import React from 'react'
import VerifiedIcon from '@mui/icons-material/Verified';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import 'animate.css';
import Dialog from './Dialog';
import SL21 from '../pics/SL21.webp';
import SL22 from '../pics/SL22.webp';
import SL23 from '../pics/SL23.webp';
import SL24 from '../pics/SL24.webp';
import SL25 from '../pics/SL25.webp';
const Why = () => {
  return (
    <div>
   <section className="destination" id="destination" >

<div className="heading">
   
    <h1 style={{color:'black'}}>Why to Choose Purvanhcal Royal City ?</h1>
    <div style={{color:'black',fontSize:"20px"}}>Purvanchal Royal City project offers opportunities to achieve your desire of lifestyle. It is spread in an area of 22 acres and the project has 2597 flats.</div>
</div>

<div className="box-container">

    <div className="box" data-aos="fade-up"  data-aos-delay="150" id="features">
        <div className="image" style={{height:"100%"}}>
        <Carousel
        autoFocus={true}
        autoPlay={true}
        infiniteLoop={true}
        
        >
                <div>
                    <img src={SL21} style={{zIndex:'-10',position:'relative'}}/>
                    
                </div>
                <div>
                    <img src={SL22} style={{zIndex:'-10',position:'relative'}}/>
                 
                    
                </div>
                <div>
                    <img src={SL23} style={{zIndex:'-10',position:'relative'}}/>
                 
                    
                </div>
                <div>
                    <img src={SL24} style={{zIndex:'-10',position:'relative'}}/>
                    
                </div>
               
               
            </Carousel>
            
        </div>
        </div>
        <div className="box" data-aos="fade-up"  data-aos-delay="150">
        <div className="image" style={{height:"280px",background:"white"}}>  
       <h3 style={{fontSize:"24px",marginTop:"0px"}}>Specifications</h3>    
<div style={{marginTop:"5px",width:"initial",fontSize:"20px",marginTop:"20px"}}><VerifiedIcon/>Biggest Clubhouse in Delhi NCR</div>
<div style={{marginTop:"5px",width:"initial",fontSize:"20px"}}><VerifiedIcon/>Kids Foundation school running in Society </div>
<div style={{marginTop:"5px",width:"initial",fontSize:"20px"}}><VerifiedIcon/>Spacious and well-ventilated property units</div>
<div style={{marginTop:"5px",width:"initial",fontSize:"20px"}}><VerifiedIcon/>Vaastu Compliant Homes </div>
<div style={{marginTop:"5px",width:"initial",fontSize:"20px"}}><VerifiedIcon/>Free maintenance for 22 months after possession</div>
<div style={{marginTop:"5px",width:"initial",fontSize:"20px"}}><VerifiedIcon/>Two Olympic size swimming pools & Private Multiplex </div>

</div>
 <div><Dialog/></div>
</div>
    </div>
</section>

    </div>
  )
}

export default Why