import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import 'animate.css';
import Dialog from './Dialog'
import {styled} from '@mui/material';
import SL11 from '../pics/SL11.webp';
import SL12 from '../pics/SL12.webp';
import SL13 from '../pics/SL13.webp';
import SL14 from '../pics/SL14.webp';
const Slider = () => {
    const CustomButtonWrapper = styled("div")(({ theme }) => ({
        [theme.breakpoints.down("lg")]: {
          display: "none",
        },
      }));
  return (
    <CustomButtonWrapper  className="container" style={{marginTop:"100px"}} id="home">
        <Carousel
        autoFocus={true}
        autoPlay={true}
        infiniteLoop={true}
        
        >
                <div>
                    <img src={SL11} style={{zIndex:'-10'}} alt='mahagun medalleo exterior '/>
                    <div style={{background:"rgb(0 0 0 / 59%)",zIndex:"90",width:"90%",height:"90%",position:"absolute"}}></div>
                    <div style={{zIndex:"100",position:"absolute",top:"35%",fontSize:"35px",left:"15%",fontWeight:"700",color:"#f1cc93",width:"70%",background:"rgb(0 0 0 / 59%)",textAlign:"center",borderRadius:"40px"}}>Purvanchal Royal City | The Most Luxury Apartments in Greater Noida</div>
                    <div style={{zIndex:"110",position:"absolute",bottom:"30%",left:"30%", borderRadius:"20px"}}><Dialog/></div>
                </div>
                <div>
                    <img src={SL12} style={{zIndex:'-10',position:'relative'}} alt='mahagun medalleo exterior 2 '/>
                    <div style={{zIndex:"100",position:"absolute",top:"35%",fontSize:"35px",left:"10%",fontWeight:"700",color:"#f1cc93",width:"80%",background:"rgb(0 0 0 / 59%)",textAlign:"center",borderRadius:"40px"}}>Purvanchal Royal City | Super Luxurious and Ultra Spacious Apartments @ 1.08 Crore</div>
                    <div style={{zIndex:"110",position:"absolute",bottom:"30%",left:"30%",borderRadius:"20px"}}><Dialog/></div>
                </div>
                <div>
                    <img src={SL13} style={{zIndex:'-10',position:'relative'}} alt='mahagun medalleo exterior 3'/>
                    <div style={{zIndex:"100",position:"absolute",top:"35%",fontSize:"35px",left:"10%",fontWeight:"700",color:"#f1cc93",width:"80%",background:"rgb(0 0 0 / 59%)",textAlign:"center",borderRadius:"40px"}}>Purvanchal Royal City Phase II | The Crown of Greater Noida</div>
                    <div style={{zIndex:"110",position:"absolute",bottom:"30%",left:"30%",borderRadius:"20px"}}><Dialog/></div>
                </div>
                <div>
                    <img src={SL14} style={{zIndex:'-10',position:'relative'}} alt='mahagun medalleo exterior 4 '/>
                    <div style={{zIndex:"100",position:"absolute",top:"35%",fontSize:"35px",left:"10%",fontWeight:"700",color:"#f1cc93",width:"80%",background:"rgb(0 0 0 / 59%)",textAlign:"center",borderRadius:"40px"}}>Purvanchal Royal City | Occupation Certificate Received. Possession Started.</div>
                    <div style={{zIndex:"110",position:"absolute",bottom:"30%",left:"35%",borderRadius:"20px"}}><Dialog/></div>
                </div>
               
            </Carousel>
    </CustomButtonWrapper >
  )
}
 
export default Slider
