import React from 'react';
import style from '.././css/style.css';

const Header = () => {
  return (
   
<header className="header" style={{background:"#ffffff"}} >

<div id="menu-btn" className="fas fa-bars"></div>

<a data-aos="zoom-in-left" data-aos-delay="150" href="tel:5554280940" className="logo"> <i className="fas fa-paper-plane"></i><img src='https://i.imgur.com/CP9E8wK.jpg' style={{height:"80px",width:"150px",objectFit:""}}/> </a>

<nav class="navbar">
    <a data-aos="zoom-in-left" data-aos-delay="300" href="#home">home</a>
    <a data-aos="zoom-in-left" data-aos-delay="450" href="#floorplan">FloorPlan</a>
    <a data-aos="zoom-in-left" data-aos-delay="600" href="#pricelist">PriceList</a>
    <a data-aos="zoom-in-left" data-aos-delay="750" href="#features">Features</a>
    <a data-aos="zoom-in-left" data-aos-delay="900" href="#location">Location</a>
    <a data-aos="zoom-in-left" data-aos-delay="1150" href="#video">Video</a>
</nav>


<div >
  <div><h2 style={{marginLeft:"15px",color:"black",fontSize:"22px",textAlign:"center"}}>For Any query Contact Us</h2></div>
  <a href="tel:8130306040"><button className="neonbtn" style={{zIndex:'100',marginLeft:"0%",marginTop:"4px"}}><h5 className="neon2" style={{zIndex:'110',position:'relative'}}>+91-81-3030-6040</h5></button></a>
</div>
</header>

    
  )
}

export default Header;