import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Dialog from '../Slider/Dialog';
const map = () => {
  return (
    <section className="destination" id="location" >

    <div className="heading">
       
        <h2 style={{color:"black",fontSize:"25px",textDecoration:"underline"}}> Purvanchal Royal City Graeter Noida: Location </h2>
    </div>
    
    <div className="box-container">
    
        <div className="box" data-aos="fade-up"  data-aos-delay="150">
            <div className="image" style={{height:"300px"}}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.6309057964627!2d77.50384631436837!3d28.43039198249798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc174c16fd017%3A0x1a4677eabd34b0bc!2sPurvanchal%20Royal%20City!5e0!3m2!1sen!2sin!4v1672478246845!5m2!1sen!2sin" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0}></iframe>
           
            </div>
            </div>
            <div className="box" data-aos="fade-up"  data-aos-delay="150">
            <div className="image" style={{height:"300px",background:"white"}}>  
           <h3 style={{fontSize:"22px",marginLeft:"15px"}}>Location Advantage </h3>    
           <div style={{fontSize:"20px",marginTop:"5px",width:"350px",marginLeft:"20px"}}><LocationOnIcon/>8 Min. Drive to Yamuna Expressway </div>
 <div style={{fontSize:"20px",marginTop:"5px",width:"350px",marginLeft:"20px"}}><LocationOnIcon/>8 Min. Drive to Expo Mart  </div>
 <div style={{fontSize:"20px",marginTop:"5px",width:"350px",marginLeft:"20px"}}><LocationOnIcon/>10 Min. Drive to Ansal Plaza </div>
 <div style={{fontSize:"20px",marginTop:"5px",width:"350px",marginLeft:"20px"}}><LocationOnIcon/>10 Min. Drive to Yatharth Hospital</div>
 <div style={{fontSize:"20px",marginTop:"5px",width:"350px",marginLeft:"20px"}}><LocationOnIcon/>Golf Course Station-6 km</div>
 <div style={{fontSize:"20px",marginTop:"5px",width:"350px",marginLeft:"20px"}}><LocationOnIcon/>10 Min. Drive to Knowledge Park 2 Metro Station  </div>
 
 <div><Dialog/></div>
 
  </div>
        </div>
    
       
        
    
     
    
    
    
    </div>
        
    
    
    </section>
  )
}

export default map