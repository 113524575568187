import React from 'react';
import { useNavigate } from 'react-router-dom';

const Thankyou = () => {
 
const navigate=useNavigate()
  
  return (
    <div style={{marginTop:'10px',width:"800px"}}>
             <div style={{display:"block"}}>
        <h1 style={{marginLeft:'10px'}}><img src='https://i.imgur.com/JGWj4Bs.png' style={{height:"300px",width:"400px"}}/></h1>
      
            <div style={{width:"400px",height:"160px",marginLeft:'0px',fontSize:"20px"}}>Thank you for showing interest in Purvanchal Royal City. We have received your Query & will get back to you soon as possible.
            In the meantime,if you can Call or WhatsApp us </div>
            <div style={{width:"400px",height:"30px",marginLeft:'20px',marginTop:"15px"}}>
            <h3 style={{marginLeft:'40px',fontSize:"25px"}}>+91-8130306040</h3>
           
            </div>
            </div>
           <button style={{marginLeft:'80px',background:"orange",height:"50px",width:"160px",borderRadius:"10px",fontSize:"25px",marginTop:"15px"}}  onClick={() => navigate('/')}> Back To Site</button>
    </div>
  )
}

export default Thankyou;